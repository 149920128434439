*,
*::before,
*::after{ 
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

img{ 
    width: 100%;
    object-fit: cover;
}

ul{ 
    list-style: none;
}

button{ 
    cursor: pointer;
    border: 0;
    outline: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
}

input{ 
    color: inherit;
    font: inherit;
    border: 0;
}

a{ 
    color: inherit;
    text-decoration: none;
}

h1,h2,h3,h4,h5,h6,p{ 
    margin: 0;
}
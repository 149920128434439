:root{ 
    /*light mode*/
    --body-light-bg:hsl(0, 0%, 98%);
    --element-light-bg:hsl(0, 0%, 100%);
    --light-mode-text:hsl(200, 15%, 8%);

    /*dark mode*/
    --dark-bg:hsl(207, 26%, 17%);
    --dark-element:hsl(209, 23%, 22%);
    --white:hsl(0, 0%, 100%);

    --text-color:var(--light-mode-text);
    --bg-color:var(--body-light-bg);
    --element-bg:var(--element-light-bg);

    --radius:.5rem;
    --transition: 300ms all ease-in-out;
}
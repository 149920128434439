@use 'vars' as *;


.details{
  overflow-y:auto ;
  position: fixed;
  inset: 0;
  z-index: 2;
  margin-top: 2rem;

  .overlay{ 
    position: fixed;
    inset: 0;
    z-index: -1;
    background-color: var(--bg-color);
  }

  &__content{ 
    margin-bottom: 2rem;
    display: grid;
    grid-template-columns: repeat( auto-fit , minmax(min(50ch , 100%), 1fr));
    gap: 3rem;
  }

  .btn{ 
    --radius:0.3rem;
    background-color: var(--element-bg);
    border-radius: var(--radius);
    padding: 0.75rem 1.25rem ;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
    font-size: 0.9rem;

    &:focus-visible{ 
      outline: var(--outline);
      outline-offset: var(--outline-offset);
    }

    &.back{ 
      font-size: 1.1rem;
      box-shadow:0px 20px 50px 3px rgba(0, 0, 0, 0.07);
      margin-bottom: 2rem;
      margin-top: 4rem;
      svg{ 
        margin-right: .5rem;
      }
    }
  }

  dl{ 
    font-size: 1.15rem;
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat( auto-fit , minmax(25ch , 1fr));
    gap: 1rem;
  }

  .flag{ 
    img{ 
      max-width: 100%;
      height: 100%;
      box-shadow:0px 20px 50px 3px rgba(0, 0, 0, 0.07);
    }
  }

  .borders{ 
    dl{ 
      display: revert;
      margin-top: 3rem;
      dt{ 
        white-space: nowrap;
      }
      div:not(.borders-container){ 
        display: block;
      }
    }
    
    &-container{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 0.75rem;
    }
  }
}
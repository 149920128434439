@use 'boilerplate' as *;
@use  'vars' as *;
.countries{ 
    margin-top:  5rem;
    display: grid;
    grid-template-columns: repeat(auto-fit , minmax(15rem , 1fr));
    grid-gap: 3.5rem;
    justify-items: center;
}

.card{ 
    background-color: var(--element-bg);
    border-radius: var(--radius);
    overflow: hidden;
    box-shadow:
    0px 51px 86px rgba(0, 0, 0, 0.07)
  ;


    &__content{ 
        padding:2rem 1.5rem;
    }

    [data-details]:focus-visible &__img{ 
        transform: scale(1.05);
    }

    &__img{ 
        height: max(10rem, 30vh);
        aspect-ratio: 3/2;
        transition: var(--transition);
        transition-duration: 250ms;
        box-shadow: 0 14px 10px rgba(0, 0, 0, 0.07);
        &:hover{ 
            transform: scale(1.1);
        }
    }
    
    &__title,&__img{ 
        cursor: pointer;
    }

    &__title{ 
        font-size: 1.25rem;
        margin-block-end: 1.25rem;

        button:focus-visible{ 
            outline: var(--outline);
            outline-offset: var(--outline-offset);
        }
    }
}